// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Turbo } from "@hotwired/turbo-rails"

try {
  Turbo.session.drive = false;

  if (window.location.pathname.startsWith('/smart_coms')) {
    Turbo.session.drive = true;
  }
} catch(error) {}

// import Rails from '@rails/ujs';
// import * as ActiveStorage from '@rails/activestorage';

// Rails.start()
// ActiveStorage.start()

require("@rails/ujs").start();
require("channels");

require('trix');
require('@rails/actiontext');
require("@rails/activestorage").start();

import Glide, { Anchors, Autoplay, Breakpoints, Controls, Images, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

// import Glide from '@glidejs/glide';
import LazyLoad from 'vanilla-lazyload';
import Litepicker from 'litepicker';
import HandyCollapse from "handy-collapse";

import 'channels';
import 'controllers';

window.Cable = require("@rails/actioncable")
window.App = {}

App.cable = Cable.createConsumer()

// Plugins
//
import Tibby from 'plugins/tibby.ts';
// import DateRangePicker from 'plugins/daterange-picker.jsx';
// import mediumZoom from 'medium-zoom'
import axios from 'axios';
import tippy, { delegate, followCursor } from 'tippy.js';
// import "zoom-vanilla.js/dist/zoom.css"
// import "zoom-vanilla.js/dist/zoom-vanilla.min.js"

// import iziToast from 'izitoast';
import SlimSelect from 'slim-select';

// import Quill from 'quill';
import { hasClass, addClass, removeClass } from '../helpers/dom-helper';

class Application {
  constructor() { }

  start(event) {
    const body = document.getElementsByTagName('body')[0];

    setTimeout(() => {
      if (document.querySelectorAll('.glide').length > 0) {
        const glide = new Glide('.glide',
          {
            dragThreshold: false,
            perView: 1
          }
        ).mount({
          Anchors, Controls, Keyboard/*, Autoplay, Breakpoints,  Images, /*, Swipe */
        });
      }
    }, 1000);

    new LazyLoad({
      elements_selector: '.lazy'
    });

    document.querySelectorAll('.form-control.date.-range').forEach((selector) => {
      new Litepicker({
        element: selector,
        selectForward: true,
        singleMode: false,
        disableWeekends: false,
        mobileFriendly: true
      });
    });

    new Tibby();
    // mediumZoom('[data-zoomable]');

    document.querySelectorAll('.select').forEach((selector) => {
      new SlimSelect({
        select: selector
      });
    })

    axios.defaults.headers.common['Accept'] = 'text/javascript';
    axios.defaults.headers.common['token'] = '951508e4-48bb-457d-8fec-76097a4eaaea';
    axios.defaults.headers.common['Content-Type'] = 'text/javascript';
    axios.defaults.headers.common['X-CSRF-Token'] = this.getCSRFToken();
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    document.querySelectorAll('.onChangeSubmit').forEach((form) => {
      form.addEventListener('change', () => {
        form.submit();
      })
    });

    document.querySelectorAll('.collapsible').forEach((element, index) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();

        const el = event.currentTarget,
              child = document.getElementById(el.attributes.href.value);

        if (child.style.display == 'none') {
          child.style.display = 'flex';
          el.style.transform = "rotate(0deg)";
        } else {
          child.style.display = 'none';
          el.style.transform = "rotate(180deg)";
        }
      }, true);
    });

    new HandyCollapse({
      nameSpace: 'hc',
      activeClass: 'is-active',
      isAnimation: true,
      closeOthers: false,
      animationSpeed: 400,
      cssEasing: 'ease',
      onSlideStart: (isOpen, contentID) => {
        console.log(isOpen);
        const buttonEl = document.querySelectorAll(`[data-hc-control='${contentID}']`);
        console.log(buttonEl);
      },
      onSlideEnd: (isOpen, contentID) => {
        console.log(isOpen);
        const contentEl = document.querySelector(`[data-hc-content='${contentID}']`);
        console.log(contentEl);
      }
    });

    // Initialize modules
    //
    this.myTippy();

    // QuillJS
    //
    // const targets = document.querySelectorAll('.editor');

    // if (targets.length > 0) {
    //   const target = targets[0],
    //         options = {
    //           theme: 'snow',
    //           placeholder: target.dataset.placeholder
    //         }

    //   const quill = new Quill(target, options);
    //         quill.on('editor-change', function(event, ...args) {
    //           document.getElementsByName(target.dataset.target)[0].value = quill.root.innerHTML;
    //         });
    // }

  }

  getCSRFToken() {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      if (meta.getAttribute('name') === 'csrf-token') {
        return meta.getAttribute('content');
      }
    }

    return null
  }
  
  success(message) {
    // iziToast.success({
    //   title: 'Success!',
    //   message: message
    // });
  }

  error() {
    // iziToast.warning({
    //   title: 'Ooops!',
    //   message: "We couldn't quite process your request. Please try again, if the probleme persist, get in touch!"
    // });
  }

  myTippy() {
    tippy.setDefaultProps({
      animation: 'scale',
      duration: 250,
      arrow: true,
      theme: 'light'
    });

    tippy('[data-tippy-content]');
    delegate('body', { target: '[data-tippy-content]' });

    tippy(document.querySelectorAll('[data-template]'), {
      interactive: true,
      trigger: 'click',
      hideOnClick: 'toggle',
      placement: 'bottom-end',
      animation: 'shift-toward',
      distance: 16,
      theme: 'dropdown',

      content(reference) {
        console.log(reference)
        try { reference.preventDefault(); } catch(e) { }

        const template = document.getElementById(reference.getAttribute('data-template'));
              // container = document.createElement('div'),
              // node = document.importNode(template.content, true);

        // container.appendChild(node);

        // return container;
        try {
          return template.innerHTML;
        } catch(e) {
          console.warn(e)
        }
      }
    });
  }
}

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

var domReady = function(callback) {
  document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
};

domReady(function() {

  new Application().start(event);

  try {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('-attached', e.intersectionRatio < 1),
      { threshold: [0, 1] }
    );
    observer.observe(document.querySelector('.sticky'));
  } catch(e) { }

  // document.addEventListener('turbolinks:load', (event) => {


  // var observer = new IntersectionObserver((entries) => {
  //   if (entries[0].intersectionRatio === 0) {
  //     console.log('hello');
  //     document.querySelectorAll('.sticky').forEach((element) => {
  //       element.classList.add('-attached');
  //     });

  //   } else if (entries[0].intersectionRatio === 1) {
  //     console.log('bye');

  //     document.querySelectorAll('.sticky').forEach((element) => {
  //       element.classList.remove('-attached');
  //     });
  //   }
  // }, { threshold: [0,1] });

  // observer.observe(document.querySelector('.sticky-tricker'));
});

// export default Application;

// try {
//     document.addEventListener('turbolinks:load', (event) => {
//     new Application().start(event)
//   });
// } catch(error) {}

try {
  document.addEventListener('turbo:load', (event) => {
    new Application().start(event)
  });
} catch(error) {}