// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { hasClass, addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = [];

  connect() { }

  public async start(event: any) {
    const url: string = this.data.get('url'),
          response: AxiosResponse = await axios.post(url, {
            status: 'started',
            user: this.data.get('user'),
            id: event.currentTarget.dataset.taskId
          });

    if (response.data.success) {
      window.location.href += "sgg=milestones";
      // @ts-ignore
      location.reload();
    }
  }

  public async complete(event: any) {
    const url: string = this.data.get('url'),
          response: AxiosResponse = await axios.post(url, {
            status: 'completed',
            user: this.data.get('user'),
            id: event.currentTarget.dataset.taskId
          });

    if (response.data.success) {
      window.location.href += "sgg=milestones";
      // @ts-ignore
      location.reload();
    }
  }

  public async na(event: any) {
    const url: string = this.data.get('url'),
          response: AxiosResponse = await axios.post(url, {
            status: 'na',
            user: this.data.get('user'),
            id: event.currentTarget.dataset.taskId
          });

    if (response.data.success) {
      window.location.href += "sgg=milestones";
      // @ts-ignore
      location.reload();
    }
  }
}