// working
// Core
//
import { Controller } from 'stimulus';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Plugins
//
import Countdown from 'react-countdown-now';
import pluralize from 'pluralize';

export default class extends Controller {
  static targets = ['countdown', 'percentage'];

  declare countdownTarget: HTMLElement;
  declare percentageTarget: HTMLElement;
  declare hasPercentageTarget: HTMLElement;

  declare startsAt: string;
  declare endsAt: string;

  declare showMissedSLAMessage: boolean;
  declare fontSize: string;

  initialize() { 
    this.showMissedSLAMessage = true;
    this.fontSize = 'huge';
  }

  connect() {
    const settings: any = JSON.parse(this.data.get('settings'));

    if (settings) {
      this.showMissedSLAMessage = settings['showMissedSLAMEssage'];
      this.fontSize = settings['fontSize'];
    }

    const dates: any = JSON.parse(this.data.get('timestamps'));

    this.startsAt = dates['starts'];
    this.endsAt = dates['ends'];

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (this.hasPercentageTarget) {
        this.calculatePercentage();
      }

      if (completed) {
        if (this.showMissedSLAMessage) {
          return <div>
            <h4 className='-line-10 -x600 -white'>SLA Missed!</h4>
          </div>
        }
      } else {
        return <div>
          <div className={ 'row align-baseline' }>

            { days >= 1 &&
              <>
                <h2 className={'-line-10 -x500'}>{days}</h2><h4 className='-x300 -line-10'>d</h4>
                <h4 className={'-line-10' + (settings ? ' -tiny ' : ' ') + 'animated flash infinite -margin-x_4'}>:</h4>
              </>
            }

            <h2 className= { '-line-10 -x500'}>{ hours }</h2>
            <h4 className='-x300 -line-10'>h</h4>


            { days < 1 &&
              <>
                <h4 className={ '-line-10' + (settings ? ' -tiny ' : ' ') + 'animated flash infinite -margin-x_4' }>:</h4>
                <h2 className={'-line-10 -x500'}>{minutes}</h2>
                <h4 className='-x300 -line-10'>m</h4>
              </>
            }
            {/* <h4 className={ '-line-10' + (settings ? ' -tiny ' : ' ') + 'animated flash infinite -margin-x_4' }>:</h4>

            <h2 className= { '-line-10 -x600'}>{ seconds }</h2>
            <h4 className='-x300 -line-10'>s</h4> */}
          </div>
        </div>;
      }
    };

    ReactDOM.render(this.render(renderer), this.countdownTarget);
  }

  private calculatePercentage() {
    const start: any = new Date(this.startsAt),
          midpoint: any = new Date(),
          finish: any = new Date(this.endsAt);

    const elapsed: number = midpoint - start,
          percentage: number = ((elapsed / (finish - start)) * 100);

    if (percentage > 100) {

    } else {
      this.percentageTarget.style.width = `${ percentage }%`;
    }
  }

  private render(renderer?: any): React.ReactElement<any>[] {
    // @ts-ignore
    return <Countdown date={ this.endsAt } renderer={ renderer } />;
  }
}