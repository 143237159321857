import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PubSub from 'pubsub-js';

class FancySelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: this.props.selectedOptions,
      availableOptions: this.props.options,
    }
  }

  componentDidMount(){
    
    if (this.props.topic) {
      this.TOKEN = PubSub.subscribe(this.props.topic, (msg, data) => {
        if (data.option) {
          let options = this.state.availableOptions;
          options.push(data.option);
          this.setState({ availableOptions: options });
        }
      });
    }
  }

  

  componentWillUnMount() {
    PubSub.unsubscribe(this.TOKEN);
  }

  updateField(value) {
    this.setState({selectedOptions: value});
  }

  fieldName() {
    if (this.props.isMulti) {
      return this.props.fieldName + "[]";
    } else {
      return this.props.fieldName;
    }
  }


  render() {

    let filterContacts = (inputValue) => {
      return this.state.availableOptions.filter((item) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    let promiseOptions = (inputValue) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterContacts(inputValue));
        }, 200);
      })
    }

    return(
      <div>
        <AsyncSelect className={this.props.cssClasses} name={this.fieldName()} isMulti={this.props.isMulti} defaultOptions={true} loadOptions={promiseOptions} value={this.state.selectedOptions || []} onChange={(value) => this.updateField(value)} />
      </div>
    )
  }

}

export default FancySelect;