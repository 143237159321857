import React from 'react';
import Select from 'react-select';
import PubSub from 'pubsub-js';
import axios from 'axios';

class ContactForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: null,
      role_group: null,
      firstname: null,
      surname: null,
      showForm: false
    }
  }
  
  toggleView() {
    this.setState({ error: null, showForm: !this.state.showForm, email: null, role_group: null, firstname: null, surname: null });
  }

  handleChange(event, field) {
    let newState = {};
    newState[field] = event.target.value;

    this.setState(newState);
  }

  submitForm() {
    let self = this;
    let user = {
      email: this.state.email,
      role_group: 'contact',
      profile_attributes: {
        firstname: this.state.firstname,
        surname: this.state.surname
      },
      memberships_attributes: [{ 
        role_id: null,
        status: 'confirmed',
        manageable_type: 'Client',
        manageable_id: this.props.manageable_id
      }]
    };

    axios.post(this.props.url, { user })
      .then(response => {

        let data = response.data;

        if (data.status != 'error') {
          if (this.props.topic) {
            PubSub.publish(self.props.topic, {
              option: { value: data.user.id, label: data.user.name }
            });

            this.setState({ error: null, showForm: false, email: null, role_group: null, firstname: null, surname: null });
          }
        } else {
          this.setState({ error: data.error });
        }
      });
    
  }


  render() {
    let form = null;
    let error = null;
    
    if (this.state.error) {
      error = <div className="row"><p className="-smaller" style={{ color: '#bf0202' }}>{this.state.error}</p></div>;
    }

    if (this.state.showForm) {
      form = (
        <div style={{ position: 'absolute', bottom: '-18px', backgroundColor: 'white', padding: '24px', border: '1px solid #cccccc', borderRadius: '5px', boxShadow: '2px 2px 5px rgba(0,0,0,0.2)'}}>
          <div className="cell-sm">
            <h4 className="-margin-bottom_3">Contacts</h4>
            <p className="-small -gray -darker">Creating a new contact will add it to the available options immediately.</p>
            {error}
            
            <div className="row">
              <div className="cell-sm">
                <div className="row form-controls">
                  <div className="field cell-sm">
                    <input name="user[email]" className="form-control" placeholder="Email address" defaultValue={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
                    <label>Email</label>
                  </div>
                </div>
              </div>

              <div className="cell-sm">
                <div className="row form-controls">
                  <div className="field cell-sm">
                    <input name="user[profile_attributes][phone]" className="form-control" placeholder="Contact number" defaultValue={this.state.phone} onChange={(e) => this.handleChange(e, 'phone')} />
                    <label>Contact number</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="cell-sm">
                <div className="row form-controls">
                  <div className="field cell-sm">
                    <input name="user[profile_attributes][firstname]" className="form-control" placeholder="First name" defaultValue={this.state.firstname} onChange={(e) => this.handleChange(e, 'firstname')} />
                    <label>Name</label>
                  </div>
                </div>
              </div>

              <div className="cell-sm">
                <div className="row form-controls">
                  <div className="field cell-sm">
                    <input name="user[profile_attributes][surname]" className="form-control" placeholder="Surname" defaultValue={this.state.surname} onChange={(e) => this.handleChange(e, 'surname')} />
                    <label>Surname</label>
                  </div>
                </div>
              </div>

            </div>
            <div className="row align-center align-middle -margin-top_2">
              <div className="btn -x4" onClick={this.submitForm.bind(this)}>Create contact</div>
              <div className="btn -gray -darker -x2 -margin-left_3" onClick={this.toggleView.bind(this)}>Cancel</div>
            </div>
          </div>
        </div>
      )
    }

    return(
      <div style={{ position: 'relative' }}>
        <div className="row align-center -margin-bottom_2">
          <div className="btn -x6" onClick={this.toggleView.bind(this)}>Add new</div>
        </div>
        {form}
      </div>
    );
  }

}

export default ContactForm;