// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

// Stimulus
//
import { Application } from 'stimulus';
import ReadMore from "stimulus-read-more";
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// StimulusReflex
//
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';

const application = Application.start();
const context     = require.context('controllers', true, /_controller\.(js|ts|tsx|jsx)$/);

application.register("read-more", ReadMore)
application.load(definitionsFromContext(context));
// StimulusReflex.initialize(application, { consumer, debug: process.env.RAILS_ENV === 'development' });

// StimulusReflex.initialize(application, { consumer, controller, debug: process.env.RAILS_ENV === 'development' });
import controller from '../controllers/application_controller';
application.consumer = consumer
StimulusReflex.initialize(application, { consumer, controller, debug: process.env.RAILS_ENV === 'development' });
