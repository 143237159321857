// not working, not working on develop
// no mapboxgl plugin
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
declare var mapboxgl;

export default class extends Controller {
  static targets = ['map'];

  declare mapTarget: any;

  declare map: any;

  declare initialCoordinates: any[];

  connect() {
    try { 
      mapboxgl.accessToken = 'pk.eyJ1Ijoic21hcnRlZGl0b3JzIiwiYSI6ImNrYmduNjEyYzE3NWQyem5vNjJzeXE0d3MifQ.MX3vn11muEy1l4dhNLUv2w';

      this.render(JSON.parse(this.data.get('coordinates')));
    } catch (error) { }
  }

  private async render(coordinates?: any[]) {
    try { 
      this.map = new mapboxgl.Map({
        container: this.mapTarget,
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 8,
        pitch: 50,
        bearing: 15,
        center: coordinates
      });

      this.map.on('load', () => {
        new mapboxgl.Marker().setLngLat(coordinates).addTo(this.map);
      });
    } catch (error) { }
  }
}