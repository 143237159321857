// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['form', 'slasContainer', 'customDatesContainer', 'selected'];

  declare formTarget: any;
  declare slasContainerTarget: any;
  declare customDatesContainerTarget: any;
  declare selectedTarget: any;
  declare hasSelectedTarget: boolean;

  connect() {
    if (!this.hasSelectedTarget) {
      this.getSLAs();
    }
  }

  public async getSLAs() {
    this.disableAndHideCustomSLAs(false);

    const params = { },
    elements = this.formTarget;

    params['sgg'] = {}
    // @ts-ignore
    try { params['sgg']['type_id'] = this.formTarget.querySelectorAll('input[name="sgg[type_id]"]:checked')[0].value; } catch(e) {}
    // @ts-ignore
    try { params['sgg']['contractor_id'] = this.formTarget.querySelectorAll('select[name="sgg[contractor_id]"]')[0].value; } catch(e) {}
    // @ts-ignore
    try { params['sgg']['status_id'] = this.formTarget.querySelectorAll('select[name="sgg[status_id]"]')[0].value; } catch(e) {}
    // @ts-ignore
    try { params['sgg']['work_type_ids'] = Array.from(this.formTarget.querySelectorAll('select[name="sgg[work_type_ids][]"] option:checked')).map(el => el.value); } catch(e) {}

    // for (let i = 0; i < elements.length; ++i) {
    //   const element: any = elements[i],
    //   name: string = element.name,
    //   value: string = element.value;

		// 	if (name) { params[name] = value }
    // }

    const response: AxiosResponse = await axios.get(this.data.get('slasUrl'), {
      params: params
    });

    this.slasContainerTarget.innerHTML = response.data;
  }

  public async setSLA(event: any) {
    event.preventDefault();
    const response: AxiosResponse = await axios.get(event.currentTarget.href);
    this.slasContainerTarget.innerHTML = response.data;
    this.disableAndHideCustomSLAs(true);
  }

  disableAndHideCustomSLAs(disable) {
    if (disable) {
      addClass(this.customDatesContainerTarget, '-state-hidden');
    } else {
      removeClass(this.customDatesContainerTarget, '-state-hidden');
    }
    const inputElements: HTMLInputElement[] = Array.from(this.customDatesContainerTarget.getElementsByTagName('INPUT'))
    inputElements.forEach((el: HTMLInputElement) => {
      el.disabled = disable;
    })
  }
}