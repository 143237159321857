// working
// Core
// 
import { Controller } from 'stimulus';

// Plugins
// 
import * as Uppy from '@uppy/core';
import * as Dashboard from '@uppy/dashboard';
import * as XHRUpload from '@uppy/xhr-upload';
import * as Form from '@uppy/form';

import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['container'];

  declare containerTarget: any;
  declare uppy: any; // Uppy is a namespace so we can't use it as a type.

  connect() {
    this.uppy = Uppy({
      id: 'uppy',
      autoProceed: false,
      allowMultipleUploads: true,
      debug: false,
      restrictions: {
        maxFileSize: 104857600, // 100MB in bytes
        maxNumberOfFiles: 4,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.doc', '.docx', '.pdf', '.xlsx', '.csv', '.sql']
      },
    });

    this.init();
  }

  private async init() {
    const uppyFormInstance: any = this.uppy.use(Form, {
      target: '#form',
      getMetaFromForm: true,
      addResultToForm: true,
      resultName: 'uppyResult',
      triggerUploadOnSubmit: false,
      submitOnSuccess: false
    });

    const form: HTMLFormElement = uppyFormInstance.plugins.acquirer[0].form;

    this.uppy.use(XHRUpload, {
      endpoint: form.action,
      headers: {
        'X-CSRF-Token': form.authenticity_token
      },
    });

    try {
      this.uppy.use(Dashboard, {
        target: '[data-uppy]',
        inline: true,
        width: '100%',
        height: 300,
        thumbnailWidth: 250,
        note: 'Images, PDF, Excel and CSV files only, 1-4 files at a time, up to 100MB',
        metaFields: [
          { id: 'upload[name]', name: 'Name', placeholder: 'specify a file name' },
          { id: 'upload[license]', name: 'License', placeholder: 'specify a licencse if there is one' }
        ],
        replaceTargetContent: true,
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        browserBackButtonClose: true,
        plugins: ['XHRUpload']
      });
    } catch(e) {
      
    }

    this.uppy.on('complete', (result: any) => {
      if (result.successful.length > 0) {
        result.successful.forEach(async (upload: any) => {
          this.containerTarget.insertAdjacentHTML('afterbegin', (await axios.get(upload.response.uploadURL)).data);
        });
      }
    });
  }
}