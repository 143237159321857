import React from 'react';
import TimelineSegment from './TimelineSegment';
import Select from 'react-select'
import moment from 'moment';

class Timeline extends React.Component {

  timeArray = [];
  blockScroll = false;

  constructor(props) {
    super(props);

    this.createTimes();
    this.prepareSelectOptions();

    this.state = {
      segments: props.segments || [],
      locations: props.locations || [],
      sggId: props.sgg_id || "",
      scheduledAt: props.scheduled_at || "",
      scheduledTo: props.scheduled_to || "",
      userId: props.user_id || "",
      uniformItems: props.uniformItems || [],
      template: props.template || false,
      title: props.title || "",
      clientId: props.client_id || "",
      notes: props.notes || "",
      scrollTarget: null,
      duration: props.duration,
      dates: [],
      days: []
    }
    this.state.dates = this.generateDateArray(props.scheduled_at, props.scheduled_to)
    this.state.days = this.generateDaysArray();
    this.addRefsToSegments();
  }

  addRefsToSegments() {
    this.state.segments.forEach(function (segment) {
      let segmentRef = React.createRef();
      segment['segmentRef'] = segmentRef;
    })
  }

  componentDidUpdate() {
    if(this.blockScroll) {
      this.blockScroll = false;
    } else {
      this.scrollToSegment();
    }
  }

  scrollToSegment() {
    if (this.state.scrollTarget && this.state.scrollTarget.current) {
      this.state.scrollTarget.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  createTimes() {
    if (this.timeArray.length == 0) {
      let minutes = [':00', ':30'];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < minutes.length; j++) {
          let minute = minutes[j];
          let hour = i.toString();

          if (i < 10) {
            hour = "0" + hour;
          }

          this.timeArray.push(hour + minute);
        }
      }
    }
  }

  updateField(value, field) {
    let newState = { scrollTarget: null };
    newState[field] = value;

    this.setState(newState);
  }

  addSegment() {
    let segmentRef = React.createRef();
    let segmentsNotForDestroy = this.state.segments.filter(segment => !segment._destroy);
    let firstSegment = segmentsNotForDestroy[0];
    let lastSegment = segmentsNotForDestroy[segmentsNotForDestroy.length - 1];

    let operators = firstSegment ? firstSegment.operators.slice() : [];

    operators = operators.filter(operator => !operator._destroy).map(function (operator) {
      return { operator_id: operator.operator_id, quantity: operator.quantity };
    })

    let newDate = lastSegment ? lastSegment.date : (this.state.scheduledAt || "");
    let newDay = lastSegment ? lastSegment.day : 1;
    let newTime = lastSegment ? this.incrementTimeByThirtyMinutes(lastSegment.time) : "00:00";

    // Added empty string for id to solve the "delete and add new segment" bug on existing timelines
    let newSegment = { id: "", segmentRef: segmentRef, _destroy: false, date: newDate, day: newDay, time: newTime, timelineTaskId: "", locationId: "", operators: operators, uniformItemIds: [], equipmentItemIds: [], duration: 0 };

    let segments = this.state.segments;

    segments.push(newSegment);
    this.sortSegments(segments);

    this.setState({ segments: segments, scrollTarget: segmentRef });
  }

  incrementTimeByThirtyMinutes(timeToIncrement) {
    let originalIndex = this.timeArray.indexOf(timeToIncrement);
    return this.timeArray[originalIndex + 1] || "00:00"
  }

  updateSegment(index, field, value) {
    let segment = this.state.segments[index];
    if(field == "date") { 
      segment.day = segment.day + this.dayDiff(value, segment.date); 
    } else if(field == "day") { 
      segment.date = this.incrementDays(segment.date, value - segment.day);
    }
    segment[field] = value;

    let segments = this.state.segments;

    segments[index] = segment;
    this.sortSegments(segments);

    // only scroll to the segment if we are altering the time, because that's the only time a segment will move
    let scrollTarget = field === "time" || "date" || "day" ? segment.segmentRef : null

    this.setState({ segments: segments, scrollTarget: scrollTarget });
  }

  removeSegment(index) {
    let segment = this.state.segments[index];

    if (segment.id) {
      this.updateSegment(index, '_destroy', "1");
    } else {
      let segments = this.state.segments;
      segments.splice(index, 1);

      this.setState({ segments: segments });
    }
  }

  // Start of date and duration methods
  // 
  // Changing start should shift dates of all segments
  // Changing the end date should not
  // minimum scheduledTo and Duration of the Timeline should not be lower than the maximum day number of the Timeline Segments
  minimumDuration() {
    return Math.max(...this.state.segments.filter(segment => !segment._destroy).map(segment => segment.day));
  }
  
  updateScheduledAt(newScheduledAt) {
    this.blockScroll = true; // otherwise we scroll to one of the updated segments
    let newScheduledTo = this.calculateNewScheduledTo(newScheduledAt)
    let newDateArray = this.generateDateArray(newScheduledAt, newScheduledTo);
    let newDayArray = this.generateDaysArray();

    let updatedSegments = this.state.segments.slice().map(segment =>  {
      segment.date = this.incrementDays(newScheduledAt, segment.day - 1) 
      return segment
    });
    
    this.setState({ scheduledAt: newScheduledAt, scheduledTo: newScheduledTo, dates: newDateArray, days: newDayArray, segments: updatedSegments });
  }

  updateScheduledTo(newScheduledTo) {
    this.blockScroll = true; // otherwise we scroll to one of the updated segments
    let newScheduledAt = this.state.scheduledAt;
    let updatedSegments = this.state.segments.slice();

    if(newScheduledAt == "") {
      // set dates based on timeline duration and segment day
      let timelineDuration = this.state.segments.length == 0 ? 1 : Math.max(...this.state.segments.filter(segment => !segment._destroy).map(segment => segment.day));
      newScheduledAt = this.incrementDays(newScheduledTo, -(timelineDuration - 1));
      updatedSegments = updatedSegments.map(segment => {
        segment.date = this.incrementDays(newScheduledAt, segment.day - 1);
        return segment;
      });
    }
    let newDateArray = this.generateDateArray(newScheduledAt, newScheduledTo);
    let newDuration = newDateArray.length;
    let newDayArray = this.generateDaysArray(newDuration);

    this.setState({ scheduledAt: newScheduledAt, scheduledTo: newScheduledTo, duration: newDuration, segments: updatedSegments, dates: newDateArray, days: newDayArray });
  }

  updateDuration(newDuration) {
    // when duration changes need to update the days array
    // and the dates array and the scheduledTo date if there are dates
    this.blockScroll = true; // otherwise we scroll to one of the updated segments
    let newScheduledTo = this.state.scheduledTo;
    let newDateArray = this.state.dates;
    if(newScheduledTo != "") {
      newScheduledTo = this.incrementDays(this.state.scheduledAt, newDuration - 1);
      newDateArray = this.generateDateArray(this.state.scheduledAt, newScheduledTo);
    }
    this.setState({ duration: newDuration, scheduledTo: newScheduledTo, days: this.generateDaysArray(newDuration), dates: newDateArray });
  }

  calculateNewScheduledTo(newScheduledAt) {
    if(this.state.scheduledAt != "" && this.state.scheduledTo != "") {
      let diffDays = this.dayDiff(newScheduledAt, this.state.scheduledAt);
      return this.incrementDays(this.state.scheduledTo, diffDays);
    } else {
      return this.incrementDays(newScheduledAt, this.state.duration - 1);
    }
  }

  minScheduledToDate() {
    // Scheduled to date must be on or after the Timeline's scheduled_at date and the last scheduled TimelineSegment
    let dates = [];
    if(this.state.scheduledAt != '') { dates.push(new Date(this.state.scheduledAt)) };
    this.state.segments.filter(segment => !segment._destroy).map((segment, i) => {
      if(segment.date != "") { dates.push(new Date(segment.date)) }
    })
    let earliestPermittedDate = new Date(Math.max(...dates));
    return this.datetimeToDateString(earliestPermittedDate);
  }

  // Dates for date drop-down on TimelineSegments
  generateDateArray(at, to) {
    let dates = [];
    if(at != "" && !!at) { dates.push(at) };
    if(to != "" && !!to) { dates.push(to) };

    if(dates.length < 2) {
      return dates;
    } else {
      let dateList = [];
      const startDate = new Date(dates[0]);
      const endDate = new Date(dates[1]);
      while (startDate <= endDate) {
        dateList = [...dateList, this.datetimeToDateString(new Date(startDate))]
        startDate.setDate(startDate.getDate() + 1)
      }
      return dateList;
    }
  }

  generateDaysArray(duration = this.state.duration) {
    let dayArray = [];
    for(var i = 0; i < parseInt(duration); i++){
      dayArray.push(i + 1); 
    }
    return dayArray;
  }

  dayDiff(newDateString, oldDateString) {
    let oldDate = moment(oldDateString,'YYYY/MM/DD');
    let newDate = moment(newDateString,'YYYY/MM/DD');
    return newDate.diff(oldDate, 'days');
  }

  datetimeToDateString(datetime) {
    return `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`
  }

  incrementDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return this.datetimeToDateString(result);
  }
  // End of date and duration methods 


  duplicateOperators(index) {
    let operators = this.state.segments[index].operators.filter(operator => !operator._destroy);
    let clonedOperators = operators.map(function (operator) { return { id: "", operator_id: operator.operator_id, quantity: operator.quantity }; })
    let segments = this.state.segments
    segments.filter((segment, i) => i != index).forEach(function (segment) {
      segment.operators.forEach(function (operator, i) {
        operator.id ? segment.operators[i]["_destroy"] = "1" : delete segment.operators[i];
      })
      JSON.parse(JSON.stringify(clonedOperators)).forEach(function (operator) {segment.operators.push(operator)});
    })
    this.setState({ segments: segments });
  }

  sortSegments(segments, andSet = false) {
    segments.forEach(segment => {
      // convert day and time into a sort key
      let timeArray = segment.time.split(':');
      segment['sortKey'] = parseInt([segment.day.toString()].concat(timeArray).join(''));
    });
    segments = segments.sort((a, b) => (a._destroy - b._destroy) === 0 ? (a.sortKey > b.sortKey) ? 1 : (b.sortKey > a.sortKey ? -1 : 0) : a._destroy - b._destroy);
  }

  prepareSelectOptions() {
    this.selectOptions = { locationOptions: [], uniform: [] };

    for (let i = 0; i < this.props.availableBuildings.length; i++) {
      let location = this.props.availableBuildings[i];
      this.selectOptions.locationOptions.push({ value: location.id, label: location.name });
    }

    for (let i = 0; i < this.props.availableUniform.length; i++) {
      let uniform = this.props.availableUniform[i];
      this.selectOptions.uniform.push({ value: uniform.id, label: uniform.name });
    }
  }

  render() {

    let noSegmentsContent = null;
    let scheduledToMininumDate = this.minScheduledToDate();

    if (this.state.segments.length == 0) {
      noSegmentsContent = (
        <div className="row">
          <div className="cell-sm"></div>
          <div className="cell-sm-auto">
            <p className="-text-center">
              There are currently no tasks in this Timeline, click 'Add Task' to start creating your Timline.
            </p>
          </div>
          <div className="cell-sm"></div>
        </div>
      );
    }
    let formMethod = this.props.formMethod;
    if (formMethod == 'patch') {
      formMethod = 'post';
    }
    return (
      <div>
        <form method={formMethod} action={this.props.formUrl}>
          {this.props.formMethod == 'patch' &&
            <input type="hidden" name="_method" value="patch" />
          }
          <div className="row">
            <div className="cell-sm"></div>
            <div className="cell-sm-8">
              <div className="form">
                <div className="field compact">
                  <input type="hidden" name="timeline[client_id]" defaultValue={this.state.clientId} />
                  <div className="row">
                    <div className="cell-sm -margin-top_2">
                      <label>Notes</label>
                      <textarea className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[notes]" value={this.state.notes} onChange={(e) => this.updateField(e.target.value, 'notes')} rows="5" />
                    </div>
                  </div>

                  <div className="row -margin-top_2">
                    <div className="cell-sm-2 -padding-right_4">
                      <label>Schedule for</label>
                      <input type="date" className="form-control -padding_3 -margin-top_3" name="timeline[scheduled_at]" style={{ backgroundColor: 'white', padding: '6px 6px 5px' }} value={this.state.scheduledAt} onChange={(e) => this.updateScheduledAt(e.target.value)} />
                    </div>

                    <input type="hidden" name={"timeline[duration]"} value={1} />

                    <div className="cell-sm -padding-right_4">
                      <label>Title (optional)</label>
                      <input className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[title]" value={this.state.title} onChange={(e) => this.updateField(e.target.value, 'title')} />
                    </div>

                    <div className="cell-sm-1">
                      <label>Template?</label>
                      <div className="align-center">
                        <input type="checkbox" className="align-middle -margin-top_2" name="timeline[template]" style={{transform: 'scale(1.3)'}} value={this.state.template} checked={this.state.template} onChange={(e) => this.updateField(e.target.checked, 'template')} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="field compact cell-sm-12">
                      <Select className="-margin-top_3" name="timeline[uniform_ids][]" isMulti={true} options={this.selectOptions.uniform} value={this.state.uniformItems || []} onChange={(value) => this.updateField(value, 'uniformItems')} />
                      <label>Required Uniform & PPE</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="cell-sm-1 -padding-right_4">
                      <label>Project</label>
                      <input className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[sgg_id]" value={this.state.sggId} onChange={(e) => this.updateField(e.target.value, 'sggId')} />
                    </div>

                    <div className="cell-sm -padding-right_4">
                      <label>Buildings</label>
                      <Select className="-margin-top_3" name="timeline[location_ids][]" isMulti={true} options={this.selectOptions.locationOptions} value={this.state.locations || []} onChange={(value) => this.updateField(value, 'locations')} />
                    </div>

                    <div className="cell-sm-4 -padding-right_4">
                      <label>Site Lead</label>
                      <select className="form-control -padding_3 -margin-top_3" name="timeline[user_id]" value={this.state.userId || ""} onChange={(e) => this.updateField(e.target.value, 'userId')}>
                        {this.props.availableUsers.map(function (user, i) {
                          return <option key={i} value={user.id}>{user.name}</option>
                        }.bind(this))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cell-sm"></div>
          </div>

          <div className="vertical-timeline vertical-timeline--one-column-left">
            <label>{this.minScheduledDate}</label>
            {this.state.segments.map(function (segment, i) {
              if (segment._destroy) {
                return (
                  <span key={i}>
                    <input type="hidden" name="timeline[timeline_segments_attributes][][id]" value={segment.id} />
                    <input type="hidden" name="timeline[timeline_segments_attributes][][_destroy]" value="1" />
                  </span>
                )
              } else {
                return <TimelineSegment index={i} key={i} {...segment} fieldPrefix="timeline[timeline_segments_attributes][]" dateArray={this.state.dates} dayArray={this.state.days} timeArray={this.timeArray} availableTasks={this.props.availableTasks} availableSeverityLevels={this.props.availableSeverityLevels} availableOperators={this.props.availableOperators} availableUniform={this.props.availableUniform} availableEquipment={this.props.availableEquipment} availableTasks={this.props.availableTasks} availableLocations={this.props.availableLocations} updateCallback={this.updateSegment.bind(this)} removeCallback={this.removeSegment.bind(this)} duplicateOperatorsCallback={this.duplicateOperators.bind(this)} />
              }
            }.bind(this))}
            {noSegmentsContent}

            <div className="row">
              <div className="cell-sm"></div>
              <div className="cell-sm-auto -padding">
                <div className="btn -margin-top" onClick={this.addSegment.bind(this)}>Add Task</div>
              </div>
              <div className="cell-sm"></div>
            </div>
          </div>
          <div className="row -margin-y -padding-top" style={{ borderTop: '1px solid #eee' }}>
            <div className="cell-sm"></div>
            <div className="cell-sm-auto">
              <div className="row">
                <a className="btn -color -gray -darker -margin-right_3" href={this.props.cancelUrl}>Cancel</a>
                <input type="submit" value="Submit" className="btn" />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

}

export default Timeline;

